import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import user from 'src/module/user';
import Auth from '../../module/Auth';

const OTPDialog = ({ open, onClose, onVerifyOTP }) => {
  const [helperText, setHelperText] = useState('');
  const otpRef = useRef('');
  const navigate = useNavigate();

  const handleVerifyOTP = () => {
    const otpData = {
      otp: otpRef.current.value,
    };

    Auth.verifyOTP(otpData, (response) => {
      if (response.status === 'success') {
        setHelperText('OTP verified successfully');
        // Call the parent component's onVerifyOTP function
        navigate('/dashboard', { replace: true });
      } else {
        setHelperText('OTP verification failed');
      }
    });
  };

  const handleResendEmail = () => {
    user.resendEmail(Auth.getUserLoginId(), (response) => {
      if (response.data == true) {
        toast.success('Code sent successfully');
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Enter OTP to verify your email</DialogTitle>
      <DialogContent>
        <DialogTitle>Email Verification</DialogTitle>
        <DialogContent>
          <p>Please verify your email address before proceeding.</p>
          <p>An email with a verification link has been sent to your email address.</p>
          <p>If you haven't received the email, please check your spam folder.</p>
        </DialogContent>
        <TextField
          inputRef={otpRef}
          onChange={(e) => setHelperText('')} // Clear the helper text when the OTP field is changed
          label="OTP"
          type="text"
          fullWidth
          margin="normal"
          error={helperText !== ''}
          helperText={helperText}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleResendEmail}>Resend</Button>
        <Button onClick={handleVerifyOTP} variant="contained" autoFocus>
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OTPDialog;
