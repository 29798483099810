import React, { useEffect, useMemo } from 'react';

import { Button, CircularProgress, Tooltip, InputBase, TextField, LinearProgress } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { toast, ToastContainer } from 'react-toastify';

import Iconify from '../iconify';

import FormDialog from '../formDialogs/formDialogs';
import domain from '../../module/domain';
import Auth from '../../module/Auth';
import tags from '../../module/tag';

import { MyContext } from '../../pages/DashboardAppPage';

export const MyContext1 = React.createContext();

export default function SplitButton({ isCd }) {
  const [open, setOpen] = React.useState(false);
  const [tag, setTags] = React.useState([]);
  const popperRef = React.useRef(null);
  const anchorRef = React.useRef(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [domains, setDomains] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [update, setUpdate] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const { domainName, setDomainName, setOpenContext, keywordData } = React.useContext(MyContext);
  const [domainLimit, setDomainLimit] = React.useState();

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        // Clicked outside the Popper, so close it
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleDocumentClick);

    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get('state');
    const token = urlParams.get('token');

    if (state && token !== null) {
      const domainId = urlParams.get('domainId');
      const domainName = urlParams.get('domainName');
      const dData = {
        state,
        domain: domainName,
        id: domainId,
      };
      // setDomainData(dData);
      if (isCd == undefined) {
        isCd = true;
      }
      Auth.setUserToken({ token, domainId, domainName, isCd });
      // Convert state to boolean and update the isCd value in Auth class
      localStorage.setItem('domainName', JSON.stringify(dData));
    }
    if (localStorage.getItem('domainName') !== 'undefined') {
      const domain = JSON.parse(localStorage.getItem('domainName'));
      if (domain?.state !== undefined) {
        const data = {
          domain: domain.domain,
          id: domain.id,
        };
        setDomainName(data);
        setLoader(false);
      } else {
        if (domain !== null && domain !== undefined) {
          setDomainName(domain);
          setLoader(false);
        }
      }
    }

    getDomainForUser();

    // if (selectedIndex == null && domainName == null) setSelectedIndex(0);
  }, [popperRef]);

  const filteredData = useMemo(() => {
    return domains.filter((item) => {
      return item?.domain.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [domains, searchQuery]);

  const handleMenuItemClick = (name, index) => {
    setOpen(false);
    setDomainName(name);
    setSearchQuery('');
    localStorage.removeItem('domainName');
    localStorage.setItem('domainName', JSON.stringify(name));

    // getTagForDomain(name?.id);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setSearchQuery('');
    setOpen(false);
  };

  const openDialogBox = (type = null) => {
    if (type === 'update') {
      setUpdate(true);
    }
    domain.domainLimit(Auth.getUserLoginId(), (response) => {
      if (response.status === 'success' && response.data !== -2) {
        setDomainLimit(response.data);
      } else if (response.status === 'success' && response.data == -2) {
        const length = 1 - domains.length;
        setDomainLimit(length);
      }
    });
    setOpenDialog(true);
  };

  const closeDialogBox = (toaster = null) => {
    setOpenDialog(false);
    if (toaster === 'yes') {
      toast.success('added');
    }
    if (toaster === 'updated') {
      toast.success('Updated Successfully');
    }
    setUpdate(false);
  };

  const getTagForDomain = (domainId) => {
    tags.getTags(domainId, (response) => {
      if (response.status === 'success') {
        setTags(response.data);
      }
    });
  };

  const getDomainForUser = () => {
    setLoader(true);
    domain.getDomainByUserId(Auth?.getUserLoginId(), (response) => {
      if (response.status === 'success') {
        setDomains([...response.data]);
        const domain = JSON.parse(localStorage.getItem('domainName'));

        if (domain == null) {
          setDomainName(response.data[0]);
          localStorage.removeItem('domainName');
          localStorage.setItem('domainName', JSON.stringify(response.data[0]));
        }
        setLoader(false);
      }
      setLoader(false);
    });
  };

  return (
    <>
      {!Auth.getIsCd() && openDialog ? (
        <MyContext1.Provider value={{ domains, setDomains, setOpenContext, setDomainName, domainName, update }}>
          <FormDialog
            open={true}
            close={closeDialogBox}
            getDomains={getDomainForUser}
            numberOfDomains={domains}
            limit={domainLimit}
          />
        </MyContext1.Provider>
      ) : null}
      <ToastContainer />

      {/* {domainName ? ( */}
      <>
        <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" sx={{ mb: 2 }}>
          {!domainName ? null : (
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={() => openDialogBox('update')}
            >
              <Iconify icon={'ant-design:setting-filled'} />
            </Button>
          )}

          <Button
            sx={{
              textTransform: 'lowercase',
              minWidth: '150px !important',
            }}
            onClick={handleToggle}
          >
            {loader ? (
              <CircularProgress size={20} />
            ) : (
              <>
                {domainName?.domain ? (
                  <img alt="hi" src={`https://www.google.com/s2/favicons?domain=${domainName?.domain}`} />
                ) : null}{' '}
                &nbsp;
                {domainName?.domain ? domainName?.domain : 'No Domain Added '}
              </>
            )}
          </Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <Iconify icon={'ant-design:caret-down-filled'} />
          </Button>
          {!Auth.getIsCd() && (
            <Tooltip title="add domain" placement="top-start">
              <Button
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={openDialogBox}
              >
                <Iconify icon={'material-symbols:add-circle'} />
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>

        {!Auth.getIsCd() && (
          <Popper
            ref={popperRef}
            sx={{
              zIndex: 999,
              height: '150px',
              overflowY: 'scroll', // Use 'scroll' to enable scrolling
              '&::-webkit-scrollbar': {
                width: '0.4em', // Set the width of the scrollbar
                backgroundColor: 'transparent', // Hide the track of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent', // Hide the scrollbar thumb
              },
            }}
            open={open}
            anchorEl={anchorRef.current}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <div>
                    <TextField
                      size="small"
                      type="text"
                      placeholder="Search…"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      value={searchQuery}
                      // onChange={(event) => {
                      //   const newValue = event.target.value.toLocaleLowerCase();
                      //   event.target.value = newValue;
                      // }}
                    />
                  </div>
                  <ClickAwayListener onClickAway={() => handleClose}>
                    <MenuList id="split-button-menu">
                      {filteredData?.map((option, index) => (
                        <MenuItem
                          key={option?.id}
                          selected={option?.domain === domainName?.domain}
                          disabled={!option?.domain} // Add disabled attribute if option.domain is falsy
                          onClick={(event) => handleMenuItemClick(option, index)}
                        >
                          <img alt="hi" src={`https://www.google.com/s2/favicons?domain=${option?.domain}`} />
                          &nbsp;
                          {option?.domain}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </>
      {/* ) : null} */}

      {/* {openDomainDialog ? <DomainSetting open={handleDomainSettingDialog} /> : null} */}
    </>
  );
}
