// search.js
import trackerApi from './trackerApi';

class Search {
    searchKeywords = (domain, keyword, callback) => {
        const encodedDomain = encodeURIComponent(domain);
        const encodedKeyword = encodeURIComponent(keyword);
        
        return trackerApi({
            method: 'GET',
            url: `/keyword/search?domain=${encodedDomain}&keyword=${encodedKeyword}`,
        })
        .then((response) => {
            callback({ status: 'success', data: response.data });
        })
        .catch((error) => {
            console.log('Search failed', error);
            callback({ status: 'error' });
        });
    };
}

export default new Search();