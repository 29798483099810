import { useState } from 'react';
import { TextField, Dialog, DialogContent, DialogActions, Button, Grid, Paper, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from 'src/module/Auth';
import forgotPassword from '../../assets/illustrations/forgotPassword.png';

export default function ForgotPasswordPopup({ open, close }) {
  const [email, setEmail] = useState('');
  const [helperText, setHelperText] = useState('');

  const handleClose = () => {
    close();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSendResetLink = async () => {
    if (email === '') {
      setHelperText('Please enter your email');
      return;
    }

    const response = await Auth.forgotPassword(email, (response) => {
      if (response.status === 'success' && response.data === true) {
        toast.success('Password reset link sent successfully to your email!');
      } else {
        toast.error('Something went wrong!');
      }
    });

    handleClose();
  };

  return (
    <>
      <Dialog sx={{ maxHeight: '600px' }} open={open} fullWidth onClose={handleClose}>
        <DialogContent>
          <Grid container component={Paper} elevation={1} borderRadius={5} sm={12} p={2}>
            <Grid item sm={6}>
              <img src={forgotPassword} alt="forgot password" />
            </Grid>
            <Grid sm={6} mt={4}>
              <Typography variant="h2" fontWeight={600} lineHeight={1}>
                Forgot Password?
              </Typography>
              <p style={{ fontSize: '12px' }}>Enter the email address associated with your account.</p>
              <TextField
                label="Enter Email Address"
                value={email}
                onChange={handleEmailChange}
                type="email"
                fullWidth
                variant="standard"
              />
            </Grid>

            <DialogActions
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <Button
                variant="contained"
                size="medium"
                disabled={email == '' ? true : false}
                onClick={handleSendResetLink}
              >
                Send
              </Button>
              <Button variant="contained" size="medium" onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </Grid>
        </DialogContent>
      </Dialog>

      <ToastContainer />
    </>
  );
}
