import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { toast, ToastContainer } from 'react-toastify';

import Iconify from '../iconify/Iconify';

import Auth from '../../module/Auth';
import domain from '../../module/domain';
import { MyContext1 } from '../domain-drop-down/dropDown';
import { MyContext } from '../../pages/DashboardAppPage';

export default function FormDialog(props) {
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const { keywordData } = React.useContext(MyContext);
  const { domains, setDomains, setOpenContext, setDomainName, domainName, update } = React.useContext(MyContext1);
  const [currentDomainIndex, setCurrentDomainIndex] = React.useState(0);
  const [currentDomain, setCurrentDomain] = React.useState({});
  const domainRef = React.useRef();

  const handleClose = (mesg) => {
    props.close(mesg);
  };
  const addDomain = (e) => {
    e.preventDefault();
    if (domainRef.current.value === '') {
      setError(true);
      setMessage(`Domain Name can't be empty *`);
      return;
    }
    let value = domainRef.current.value;
    if (value.startsWith('https://')) {
      value = value.replace('https://', '');
    }
    if (value.startsWith('www.')) {
      value = value.replace('www.', '');
    }
    if (value.endsWith('/')) {
      value = value.replace('/', '');
    }

    const domainData = {
      userId: Auth?.getUserLoginId(),

      domain: value,
    };
    if (update) {
      domain.updateDomain(domainName?.id, domainData, (response) => {
        if (response.status === 'success') {
          // setDomains([...domains, response.data]);
          props.getDomains();
          handleClose('yes');
          setDomainName(response.data);
        }
      });
    } else {
      domain.saveDomain(domainData, (response) => {
        if (response.status === 'success') {
          setDomains([...domains, response.data]);
          setDomainName(response.data);
          handleClose('yes');
          setTimeout(() => {
            setOpenContext(true);
          }, 500);
        }
      });
    }
  };

  const onKeyDown = (e) => {
    setCurrentDomain({ domain: e.target.value });
    if (domainRef.current.value.replace(/\s/g, '') !== domainRef.current.value) {
      setError(true);
      setMessage(`Space is not allowed *`);
    } else {
      setError(false);
    }
  };

  const handleDelete = (id) => {
    if (keywordData?.length > 0) {
      toast.error(`Can't delete domain, contains keywords`);
    } else {
      domain.deleteDomain(id, (response) => {
        if (response.status === 'success') {
          toast.success('Domain Deleted');

          setDomains(domains.filter((domain) => domain.id !== id));
          setDomainName(domains[0]);

          // Check if the deleted domain was the currently selected domain
          const deletedDomainIndex = domains.findIndex((domain) => domain.id === id);
          if (deletedDomainIndex === currentDomainIndex) {
            // If the deleted domain was the last domain, set the currentDomainIndex to the previous domain
            // Otherwise, keep the currentDomainIndex the same
            const newDomainIndex =
              deletedDomainIndex === domains.length - 1 ? deletedDomainIndex - 1 : deletedDomainIndex;
            setCurrentDomainIndex(newDomainIndex);
            setDomainName(domains[newDomainIndex]);
          }

          handleClose();
        }
      });
    }
  };

  const handleDomainChange = (event) => {
    setCurrentDomain(event.target.value);
    setDomainName(event.target.value);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="xs" open={props.open} onClose={handleClose}>
        <DialogTitle>
          {update ? 'Update Domain name' : 'Add New Domain'}
          {update ? (
            <Iconify
              icon={'ic:baseline-delete'}
              width={24}
              height={20}
              sx={{
                cursor: 'pointer',
                color: '#ff0000bf',
                float: 'right',
              }}
              onClick={() => handleDelete(domainName.id)}
            />
          ) : null}
        </DialogTitle>

        <DialogContent>
          {update && domains.length > 1 && (
            <TextField
              select
              label="Domain"
              fullWidth
              value={currentDomain?.domain}
              variant="standard"
              onChange={handleDomainChange}
              name="domain"
            >
              {domains.map((domain, index) => (
                <MenuItem key={index} value={domain}>
                  {domain.domain}
                </MenuItem>
              ))}
            </TextField>
          )}

          {update && domains.length > 1 ? (
            <TextField
              error={error}
              autoFocus
              margin="dense"
              // placeholder="example.com"
              id="domain"
              label={!error ? 'Domain Url' : message}
              type="text"
              fullWidth
              variant="standard"
              disabled={keywordData?.length > 0 ? true : false}
              inputRef={domainRef}
              defaultValue={''}
              value={currentDomain?.domain !== undefined ? currentDomain.domain : domainName.domain}
              onChange={(e) => onKeyDown(e)}
            />
          ) : (
            <TextField
              // error={error}
              autoFocus
              margin="dense"
              placeholder={props.limit == 0 ? 'Domain Limit Exceeded' : 'example.com'}
              id="domainurl"
              // label={!error ? 'Domain Url' : message}
              type="text"
              fullWidth
              disabled={props.limit == 0 || (update && keywordData.length > 0) ? true : false}
              variant="standard"
              inputRef={domainRef}
              defaultValue={update && currentDomain.domain === undefined ? domainName?.domain : null}
              onChange={(e) => onKeyDown(e)}
            />
          )}

          {update && keywordData?.length > 0 ? (
            <>
              <span
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                Can't Update this Domain, Contains keywords
              </span>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <Button disabled={error || (update && keywordData?.length > 0) || props.limit == 0} onClick={addDomain}>
            {update ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
