import React from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Divider, Box, Grid, Paper } from '@mui/material';
import ContactForm from 'src/components/ContactForm/ContactForm';
import { bgBlur } from '../utils/cssStyles';
import Logo from '../components/logo';
import loginImg from '../assets/illustrations/wave.png';

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Support() {
  return (
    <>
      <Helmet>
        <title> Support| Google Rank Check </title>
      </Helmet>
      <StyledRoot>
        <StyledToolbar>
          <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
            <Logo />
          </Box>
        </StyledToolbar>
      </StyledRoot>
      <Grid container marginTop={'100px'}>
        <Grid
          container
          justifyContent="center"
          height={'230px'}
          component={Paper}
          elevation={3}
          m={2}
          p={1}
          style={{
            backgroundImage: `url(${loginImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundColor: '#d1e3e3',
          }}
        >
          <Box
            sx={{
              padding: '50px',
            }}
          >
            <Typography
              variant="h3"
              align="center"
              sx={{
                color: '#FFF',
              }}
            >
              We are happy to help you!
            </Typography>
          </Box>
        </Grid>

        <Grid container justifyContent={'center'}>
          <Grid sm={12} md={5} mt={2}>
            <ContactForm />
          </Grid>
          <Grid sm={12} md={5}>
            <Box item component={Paper} elevation={6} square m={2} p={2}>
              <h2
                style={{
                  color: '#00A3D8',
                }}
              >
                Contact Info
              </h2>

              <h4
                style={{
                  color: '#00A3D8',
                }}
              >
                Address
              </h4>
              <Divider />

              <p>
                House No 3, Shahmiris House 3rd Floor, Qammarwari 2nd Petrol Pump Near City Mart Grocery Shopping Store
                190017.
              </p>

              <h4
                style={{
                  color: '#00A3D8',
                }}
              >
                Email us
              </h4>
              <Divider />

              <p>ranktracker@marketinglad.io</p>

              <h4
                style={{
                  color: '#00A3D8',
                }}
              >
                Call us at
              </h4>
              <Divider />

              <p>+91 6005 10 5758</p>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
