import React, { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

const ContactForm = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://forms.leadgenapp.io/js/lf.min.js/03ddaec9-5894-488c-b734-a2390274ae10';
      script.async = true;

      script.onload = () => {
        setLoading(false);
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [loading]);

  return (
    <div
      id="leadgen-form-wrap-03ddaec9-5894-488c-b734-a2390274ae10"
      style={{
        display: 'block',
        border: '0',
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      {loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={300} // Adjust the height as needed
        />
      ) : (
        <leadgen-form-03ddaec9-5894-488c-b734-a2390274ae10 />
      )}
    </div>
  );
};

export default ContactForm;
