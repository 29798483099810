import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";

const Test = () => {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          textAlign: "center",
          backgroundColor: "#f4f4f4",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          Choose Your Subscription Plan
        </Typography>

        <Box
          sx={{
            marginBottom: "20px",
            width: "100%",
            maxWidth: "600px",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: 2,
          }}
        >
          <script async src="https://js.stripe.com/v3/pricing-table.js" />
          <stripe-pricing-table
            pricing-table-id="prctbl_1OCF69SHyeWGBVACmQuOdJrc"
            publishable-key="pk_live_51OBt7HSHyeWGBVACCVgt1TVbzVvlphOOZeq0GIt6tHqeNeBT3sZCUVDAoj1Jcsqyv2iKxajLVdImDvmVUQjWnTVU001TARSAmj"
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "20px" }}
          onClick={() => window.location.reload()}
        >
          Refresh Page
        </Button>
      </Box>
    </Container>
  );
};

export default Test;
