import trackerApi from './trackerApi';

class Tag {
  // Tag  Class //

  // Get Tags //
  getTags = (domainId, callBack) => {
    return trackerApi({
      method: 'GET',
      url: `/tags/domain-id/${domainId}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Authentication failed', error);
        callBack({ status: 'error' });
      });
  };

  // Save Tag //
  saveTag = (tagData, callBack) => {
    return trackerApi({
      method: 'POST',
      url: `/tags`,
      data: tagData,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Authentication failed', error);
        callBack({ status: 'error' });
      });
  };
}
export default new Tag();
