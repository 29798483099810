import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, Stack, IconButton, InputAdornment, TextField, LinearProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ForgotPasswordPopup from 'src/components/reset-password/reset';
import Iconify from '../../../components/iconify';
import Auth from '../../../module/Auth';
import region from '../../../module/region';
import OTPDialog from '../../../components/otp/otp-verification';

export default function LoginForm() {
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState('');
  const [helperTextPassword, setHelperTextPassword] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Check if the email is verified on component mount
    setVerifyEmail(!Auth.getVerified());
  }, []);

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const loginData = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    if (loginData.email === '' || loginData.password === '') {
      setHelperTextEmail('Email is required');
      setHelperTextPassword('Password is required');
      setLoading(false);
    } else {
      Auth.authenticate(loginData, (response) => {
        if (response.status === 'success') {
          toast.success('Login Success');
          if (Auth.getVerified()) {
            navigate('/dashboard', { replace: true });
          } else {
            setLoading(false);
            setOtpDialogOpen(true);
          }
          region.getRegions();
        } else {
          toast.error('Login Failed');
          setLoading(false);
        }
      });
    }
  };

  const handleVerifyOTP = () => {
    OTPDialog.handleVerifyOTP();
    setOtpDialogOpen(false);
    navigate('/dashboard', { replace: true });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          <TextField
            onChange={() => setHelperTextEmail(false)}
            error={helperTextEmail}
            helperText={helperTextEmail}
            inputRef={emailRef}
            name="email"
            type="email"
            label="Email"
          />

          <TextField
            error={helperTextPassword}
            helperText={helperTextPassword}
            onChange={() => setHelperTextPassword(false)}
            name="password"
            label="Password"
            inputRef={passwordRef}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row-reverse" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link variant="subtitle2" underline="hover" onClick={handleOpen} sx={{ cursor: 'pointer' }}>
            Forgot password?
          </Link>
        </Stack>

        {loading ? (
          <LinearProgress />
        ) : (
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Login
          </LoadingButton>
        )}

        <ToastContainer />
      </form>

      {open ? <ForgotPasswordPopup open={open} close={() => setOpen(false)} /> : null}
      {otpDialogOpen && (
        <OTPDialog open={otpDialogOpen} onClose={() => setOtpDialogOpen(false)} onVerifyOTP={handleVerifyOTP} />
      )}
    </>
  );
}
