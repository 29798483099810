import { 
    Box, 
    Container, 
    Typography, 
    Link, 
    Stack,
    useTheme,
    useMediaQuery 
  } from '@mui/material';
  
  const CopyrightFooter = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const currentYear = new Date().getFullYear();
  
    return (
      <Box
        component="footer"
        sx={{
          py: { xs: 2, md: 3 },
          px: { xs: 2, md: 4 },
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="lg">
          {/* Desktop View */}
          {!isMobile && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* Powered By - Left */}
              <Typography variant="body2" color="text.secondary">
                Powered by Marketing Lad
                
              </Typography>
  
              {/* Copyright - Center */}
              <Typography variant="body2" color="text.secondary">
                {'Copyright © '}
                <Link color="inherit" href="https://googlerankcheck.com/">
                  Google Rank Check
                </Link>{' '}
                {currentYear}
                {'. All rights reserved.'}
              </Typography>
  
              {/* Links - Right */}
              <Stack direction="row" spacing={2}>
                <Link
                  href="/privacy-policy"
                  color="text.secondary"
                  underline="hover"
                  sx={{ fontSize: '0.875rem' }}
                >
                  Privacy Policy
                </Link>
                <Link
                  href="/terms-of-use"
                  color="text.secondary"
                  underline="hover"
                  sx={{ fontSize: '0.875rem' }}
                >
                  Terms of Service
                </Link>
                <Link
                  href="/support"
                  color="text.secondary"
                  underline="hover"
                  sx={{ fontSize: '0.875rem' }}
                >
                  Contact Us
                </Link>
              </Stack>
            </Box>
          )}
  
          {/* Mobile View */}
          {isMobile && (
            <Stack spacing={2} alignItems="center">
              <Typography variant="body2" color="text.secondary">
                Powered by Google Rank Check
              </Typography>
  
              <Typography 
                variant="body2" 
                color="text.secondary" 
                align="center"
                sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
              >
                {'Copyright © '}
                <Link color="inherit" href="https://googlerankcheck.com/">
                  Google Rank Check
                </Link>{' '}
                {currentYear}
                {'. All rights reserved.'}
              </Typography>
  
              <Stack
                direction="column"
                spacing={1}
                alignItems="center"
              >
                <Link
                  href="/privacy-policy"
                  color="text.secondary"
                  underline="hover"
                  sx={{ fontSize: '0.75rem' }}
                >
                  Privacy Policy
                </Link>
                <Link
                  href="/terms-of-use"
                  color="text.secondary"
                  underline="hover"
                  sx={{ fontSize: '0.75rem' }}
                >
                  Terms of Service
                </Link>
                <Link
                  href="/support"
                  color="text.secondary"
                  underline="hover"
                  sx={{ fontSize: '0.75rem' }}
                >
                  Contact Us
                </Link>
              </Stack>
            </Stack>
          )}
        </Container>
      </Box>
    );
  };
  
  export default CopyrightFooter;


