import axios from 'axios';
import config from 'src/config/config';
import Auth from './Auth';

// main url

// const devApi = config.development.apiUrl;
const mainApi = config.production.apiUrl;

const UpstoreApi = (config) => {
  const token = Auth.getToken();
  if (token != null) {
    config.headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      //   Authorization: 'Bearer ' + token,
      Authorization: `Bearer ${token}`,
    };
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: 'INETRNAL SERVER ERROR',
          status: 500,
        };
      }
      if (error.response.status === 401) {
        Auth.isAuthenticated() ? Auth.logout() : Auth.removeLoginDetails();
        throw error;
      }
      return Promise.reject(error);
    }
  );
  // config.baseURL = devApi;
  config.baseURL = mainApi;
  return axios(config);
};
export default UpstoreApi;
