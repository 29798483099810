import trackerApi from './trackerApi';

class Subscription {
  // Subscription  Class //

  // Get Subscription Details //
  getSubscriptionDetails = (userId, callBack) => {
    return trackerApi({
      method: 'GET',
      url: `/subscription/get-subscription-user/${userId}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Authentication failed', error);
        callBack({ status: 'error' });
      });
  };

}
export default new Subscription();
