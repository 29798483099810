import * as React from 'react';

// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';
import { MyContext } from '../../../pages/DashboardAppPage';
import position from '../../../module/position';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
  const { keywordData, setUps, setDowns, setAvg, ups, downs, avg ,refresh , setRefresh } = React.useContext(MyContext);

  React.useEffect(() => {
    if (keywordData?.length > 0) {
      getCardData();
    } else {
      setUps(0);
      setDowns(0);
      setAvg(0);
    }
  }, [keywordData] ,refresh );

  const getCardData = () => {
    const ids = [];
    keywordData?.forEach((data) => ids.push(data.id));
    position.getCardData(ids, (response) => {
      if (response.status === 'success') {
        setUps(response.data.ups);
        setDowns(response.data.downs);
        setAvg(response.data.avg);
      }
    });
  };
  return (
    <>
      <>
        <Card
          sx={{
            p: 1,
            boxShadow: 0,
            textAlign: 'center',
            height: '150px',
            color: (theme) => theme.palette[color].darker,
            bgcolor: (theme) => theme.palette[color].lighter,
          }}
          {...other}
        >
          <StyledIcon
            sx={{
              color: (theme) => theme.palette[color].dark,
              backgroundImage: (theme) =>
                `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                  theme.palette[color].dark,
                  0.24
                )} 100%)`,
            }}
          >
            <Iconify icon={icon} width={18} height={18} />
          </StyledIcon>

          {title == "UP'S" ? (
            <>
              <Typography variant="subtitle1">
                {fShortenNumber(ups ? ups.toString().replace(/(?<!-)0+$/, '') : '0')}
              </Typography>

              <Typography variant="h6" sx={{ opacity: 0.72 }}>
                {title}
              </Typography>
            </>
          ) : null}

          {title == "DOWN'S" ? (
            <>
              <Typography variant="subtitle1">
                {fShortenNumber(downs ? downs.toString().replace(/^-|(?<=\d)0+$/, '') : '0')}
              </Typography>

              <Typography variant="h6" sx={{ opacity: 0.72 }}>
                {title}
              </Typography>
            </>
          ) : null}

          {title == 'AVERAGE' ? (
            <>
              <Typography variant="subtitle1">
                {fShortenNumber(avg ? avg.toString().replace(/^-|(?<=\d)0+$/, '') : '0')}
              </Typography>

              <Typography variant="h6" sx={{ opacity: 0.72 }}>
                {title}
              </Typography>
            </>
          ) : null}
        </Card>
      </>
    </>
  );
}
