import trackerApi from './trackerApi';

const addCsvFile = async (id,file, callBack) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    // formData.append('id', id);

    const response = await trackerApi({
      method: 'POST',
      url: `/keyword/bulk-import/${id}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    callBack({ status: 'success', data: response.data });
  } catch (error) {
    console.log('failed', error);
    callBack({ status: 'error', data: error });
  }
};


export  default addCsvFile;

