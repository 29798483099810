import { Navigate, useRoutes } from 'react-router-dom';
import React from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import ProtectedRoute from './protectedRoutes';
import Signup from './pages/SignUpPage';
import Billing from './pages/BillingPage';
import PricingPage from './pages/Subscription';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ResetPassword from './pages/ResetPassword';
import ShareDashboard from './pages/ShareDashboard';
import Terms from './pages/Terms';
import Support from './pages/Support';
import FreeAccount from './pages/FreeAccount';
import Test from './pages/test';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'test',
      element: <Test />,
    },
    {
      path: 'signup',
      element: <Signup />,
    },
    {
      path: 'free-account',
      element: <FreeAccount />,
    },
    {
      path: 'billing',
      element: (
        <ProtectedRoute element={<Billing />}>
          <Billing />
        </ProtectedRoute>
      ),
    },
    {
      path: 'subscription',
      element: (
        <ProtectedRoute element={<PricingPage />}>
          <PricingPage />
        </ProtectedRoute>
      ),
    },
    {
      path: 'dashboard',
      element: (
        <ProtectedRoute element={<DashboardLayout />}>
          <DashboardAppPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: <DashboardAppPage />,
          index: true,
        },
        {
          path: 'client-dashboard',
          element: (
            <ProtectedRoute element={<ShareDashboard />}>
              <ShareDashboard />
            </ProtectedRoute>
          ),
        },
      ],
    },

    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
    {
      path: 'privacy-policy',

      element: <PrivacyPolicy />,
    },
    {
      path: 'terms-of-use',

      element: <Terms />,
    },
    {
      path: 'support',

      element: <Support />,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
    },
    {
      path: 'client-dashboard',
      element: <ShareDashboard />,
    },
  ]);

  return routes;
}
