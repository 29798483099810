import React from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Divider, Box, Grid, Paper } from '@mui/material';
import { bgBlur } from '../utils/cssStyles';
import Logo from '../components/logo';
import loginImg from '../assets/illustrations/wave.png';

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Terms() {
  return (
    <>
      <Helmet>
        <title> Terms & Conditions | Google Rank Check </title>
      </Helmet>
      <StyledRoot>
        <StyledToolbar>
          <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
            <Logo />
          </Box>
        </StyledToolbar>
      </StyledRoot>
      <Grid container marginTop={'100px'}>
        <Grid
          container
          justifyContent="center"
          height={'230px'}
          component={Paper}
          elevation={3}
          m={2}
          p={1}
          style={{
            backgroundImage: `url(${loginImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundColor: '#d1e3e3',
          }}
        >
          <Box
            sx={{
              padding: '50px',
            }}
          >
            <Typography
              variant="h3"
              align="center"
              sx={{
                color: '#FFF',
              }}
            >
              Google Rank Check powered by Marketing Lad Terms of Service Agreement
            </Typography>
            <small
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Last Updated on : 01-07-2023
            </small>
          </Box>
        </Grid>

        <Grid item sm={12} style={{ margin: '20px' }}>
          <Box item component={Paper} elevation={6} square m={2} p={2}>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Disclaimer
            </h2>
            <Divider />

            <p>
              Welcome to Google Rank Check powered by Marketing Lad, where we aim to provide you with reliable and
              valuable services. It's important to clarify upfront that the materials presented on our website are
              provided "as is." We make no explicit or implied warranties and disclaim any implied warranties or
              conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual
              property or other rights. Moreover, we cannot guarantee the accuracy, potential outcomes, or reliability
              of the materials on our website or on any sites linked to it
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Limitations of Liability
            </h2>
            <Divider />

            <p>
              In no event shall Google Rank Check or its suppliers be held liable for any damages, including but not
              limited to, loss of data, profit, or business interruption arising from the use or inability to use the
              materials on Google Rank Check's website. Even if Google Rank Check or a duly authorized representative
              has been notified, in writing or orally, of the potential for such damages. In jurisdictions where
              limitations on implied warranties or limitations of liability for consequential or incidental damages are
              not allowed, these limitations may not apply to you.
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Revisions and Errata
            </h2>
            <Divider />
            <p>
              Materials displayed on Google Rank Check's website may contain technical, typographical, or photographic
              errors. While we strive for accuracy, we cannot warrant that any materials on our website are entirely
              accurate, complete, or up-to-date. We reserve the right to modify materials at any time without prior
              notice, but we do not commit to ensuring they are updated.
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Subscription Agreement
            </h2>
            <Divider />
            <p>
              Your subscription to Google Rank Check continues on a month-to-month basis unless terminated. To access
              our services, you must provide a current, valid, accepted payment method, which you may update
              periodically. By not cancelling your subscription before the next monthly billing date, you authorize us
              to charge the subscription fee for the upcoming month to your payment method. You can request specific
              subscription details by emailing our support team at ranktracker@marketinglad.io.
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Billing
            </h2>
            <Divider />
            <p>
              Your subscription fee for Google Rank Check's services, as well as any associated charges (such as taxes
              and transaction fees), will be billed monthly to your designated Payment Method. In cases where the
              payment date must change, such as due to unsettled payments or subscription commencement on a non-standard
              date, you can contact our support team to confirm your new payment date. Additionally, your payment dates
              are included in the monthly receipts you receive from Google Rank Check.
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Payment Methods
            </h2>
            <Divider />
            <p style={{ paddingBottom: '30px' }}>
              Updating your Payment Method is possible by requesting a link through an email to our support team at
              ranktracker@marketinglad.io. Further instructions for Payment Method updates can be found at
              www.marketinglad.io/rank-tracker/ If a payment fails due to expiration, insufficient funds, or other
              reasons, and you do not adjust your Payment Method or cancel your account, we may suspend your access to
              our service until a valid Payment Method is provided. You are responsible for any uncollected amounts due
              to these changes. Depending on your Payment Method, certain fees may be charged by the issuer, such as
              foreign transaction fees. Local tax charges may also vary based on your Payment Method. Specific fee
              details should be obtained from your Payment Method service provider.
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Cancellation Policy
            </h2>
            <Divider />

            <p style={{ paddingBottom: '30px' }}>
              You can cancel your subscription to Google Rank Check at any time, retaining access to our service until
              the end of your current billing period. As a general policy, payments are non-refundable, and we do not
              provide refunds or credits for partial-month subscription periods. To initiate a cancellation, send an
              email to our support team at ranktracker@marketinglad.io. Upon cancellation, your account will
              automatically close at the conclusion of your current billing period.
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Changes to Pricing and Service Plans
            </h2>
            <Divider />

            <p style={{ paddingBottom: '30px' }}>
              While we may periodically modify our service plans and prices, these changes will only affect you no
              sooner than 30 days following notice.
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Website Terms of Use Modifications
            </h2>
            <Divider />

            <p style={{ paddingBottom: '30px' }}>
              Google Rank Check may revise these terms of use for its website at any time without notice. Your use of
              this website signifies your agreement to be bound by the most current version of these Terms and
              Conditions of Use.
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Governing Law
            </h2>
            <Divider />

            <p style={{ paddingBottom: '30px' }}>
              Any claim concerning Google Rank Check's website shall be governed by the laws of the State of Srinagar,
              Jammu and Kashmir without regard to conflicts of law provisions. These General Terms and Conditions are
              applicable to the use of a website.
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Customer Support
            </h2>
            <Divider />

            <p style={{ paddingBottom: '30px' }}>
              We are dedicated to providing top-notch support to our users. For sales or technical inquiries, please
              reach out to us via email at ranktracker@marketinglad.io or call us at +91 6005-10-5758.
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Refunds
            </h2>
            <Divider />

            <p style={{ paddingBottom: '30px' }}>
              If our services do not meet your expectations, you have the option to request a full refund within the
              first 30 days of your new subscription. Refunds are managed by our customer support staff and can be
              requested by contacting ranktracker@marketinglad.io. However, it's important to note that refunds do not
              apply to free credits or services. Google Rank Check upholds the right to ensure customer satisfaction,
              and all refund processes will be handled diligently and fairly.
            </p>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
