import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export const Support = (props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleEmailClick = () => {
    window.location.href = 'mailto:ranktracker@marketinglad.io';
  };

  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: '#f50057', fontWeight: 'bold', marginBottom: 2 }}
          >
            Have Questions?
          </Typography>
          <Typography id="modal-modal-description" sx={{ color: '#333', fontSize: '16px', textAlign: 'center' }}>
            We're here to help! Reach out to us at:
          </Typography>
          <a href="mailto:ranktracker@marketinglad.io" style={{ textDecoration: 'none' }} onClick={handleEmailClick}>
            <Typography sx={{ color: '#19a1fc', textAlign: 'center' }}>ranktracker@marketinglad.io</Typography>
          </a>
        </Box>
      </Modal>
    </div>
  );
};
