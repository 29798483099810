

const config = {
    development: {
      apiUrl: 'https://dev-ranktracker-api-dfy6gslhza-uc.a.run.app/ml-tracker',
    },
    production: {
      apiUrl:'https://app.marketinglad.io:8443/ml-tracker'
      // apiUrl: 'https://ranktrackerbe-dfy6gslhza-uc.a.run.app/ml-tracker',
      // apiUrl:'http://localhost:8080/ml-tracker'
    },
  };
  
  export default config;
  