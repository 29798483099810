import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

function CustomerPortalButton() {
  const navigate = useNavigate();

  const handlePortalClick = () => {
    navigate('/billing');
  };

  return (
    <>
      <Typography onClick={handlePortalClick}>Profile</Typography>
    </>
  );
}

export default CustomerPortalButton;
