import * as React from 'react';
import { createContext } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

import { styled } from '@mui/material/styles';
import './style.css';

import { Box, ButtonGroup, CircularProgress, Divider, Grid, Link, Stack, TableCell, TableRow } from '@mui/material';
import Iconify from '../iconify/Iconify';

import { AppWebsiteVisits } from '../../sections/@dashboard/app';
import position from '../../module/position';

export const GarphContext = createContext();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  margin: theme.spacing(0.5),

  textAlign: 'left',
  color: theme.palette.text.primary,
}));

export default function KeywordHistory(props) {
  const [otherLinks, setOtherLinks] = React.useState([]);
  const [keys, setKeys] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [page, setPage] = React.useState(props?.position);

  React.useEffect(() => {
    if (props?.position != undefined) {
      handleOtherLinksData(page);
    }
  }, []);

  const handleClose = () => {
    props.handlePropClose();
  };

  const handlePrev = () => {
    const newPage = page - 10;
    if (newPage >= 0) {
      setPage(newPage);
      handleOtherLinksData(newPage);
    }
  };

  const handleNext = () => {
    setPage(page + 10);
    handleOtherLinksData(page + 10);
  };
  const chartData = {
    labels: keys,
    type: 'area',

    datasets: [
      {
        data: values,
        name: 'Position',
        backgroundColor: '#cfa8ea',
      },
    ],
  };

  const handleOtherLinksData = (page) => {
    setLoading(true);
    position.getOtherPositionLinks(props.rowData.id, page, (response) => {
      if (response.status === 'success') {
        setOtherLinks(response.data);
      }
    });
    position.getGraphData(props.rowData.id, '15Days', (response) => {
      setLoading(true);

      if (response.status === 'success') {
        const data = response.data;
        const abc = data.map((d) => {
          return d.position;
        });
        setValues(abc);
        const def = data.map((d) => {
          return d.creationDate;
        });
        setKeys(def);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  if (props.position === undefined) {
    return null; // Don't render the component if props.position is undefined
  }

  return (
    <div>
      <Dialog
        open={props.open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress size={40} />
            </div>
          ) : (
            <DialogContentText>
              <GarphContext.Provider value={{ setKeys, keys, setValues, values }}>
                <>
                  <AppWebsiteVisits
                    title="Keyword Position History"
                    chartLabels={keys}
                    chartData={chartData}
                    handleOtherLinksData={handleOtherLinksData}
                    id={props.rowData.id}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                      <Typography m={2} variant="subtitle1">
                        Position
                      </Typography>

                      <Box sx={{ width: '100%' }}>
                        {otherLinks?.map((links) => {
                          return (
                            <Stack>
                              <Item
                                style={
                                  {
                                    // borderRadius: '20px',
                                    // background: '#e0e0e0',
                                    // boxShadow: ' 20px 20px 60px #bebebe',
                                  }
                                }
                              >
                                <Divider />
                                <Typography ml={2} variant="subtitle1">
                                  {links?.position}
                                </Typography>
                              </Item>
                            </Stack>
                          );
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography m={2} variant="subtitle1">
                        Other Links
                      </Typography>

                      <Box ml={2} sx={{ width: '100%' }}>
                        {otherLinks?.map((links) => {
                          return (
                            <Stack>
                              <Item
                                style={
                                  {
                                    // borderRadius: '20px',
                                    // background: '#e0e0e0',
                                    // boxShadow: ' 20px 20px 60px #bebebe',
                                  }
                                }
                              >
                                <Divider />
                                <Link
                                  variant="subtitle1"
                                  underline="hover"
                                  target={'_blank'}
                                  rel="noopener nofollow"
                                  href={links.url}
                                >
                                  {links?.url}
                                </Link>
                              </Item>
                            </Stack>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ marginTop: '1rem' }}>
                    <ButtonGroup variant="outlined">
                      <Button disabled={page === 0} onClick={() => handlePrev()}>
                        Prev
                      </Button>
                      <Button onClick={() => handleNext()}>Next</Button>
                    </ButtonGroup>
                  </Box>
                </>
              </GarphContext.Provider>
            </DialogContentText>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
