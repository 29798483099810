import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack } from '@mui/material';
import Authwrap from 'src/components/otp/otp-verification';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import SignupImg from '../assets/illustrations/1 (4).png';
// sections
import { SignUpForm } from '../sections/auth/login/signUpIndex';
import Auth from '../module/Auth';
import region from '../module/region';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 450,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function FreeAccount() {
  const [signUpFail, setSignUpFail] = React.useState(false);
  const [urlParam, setUrlParam] = React.useState(window.location.href);
  const navigate = useNavigate();

  //   const responseGoogle = (response) => {
  //     const data = jwtDecode(response.credential);

  //     const loginDataObj = {
  //       email: data.email,
  //       name: data.name,
  //     };
  //     const login = googleSignIn(loginDataObj);
  //     if (login === undefined) {
  //       setSignUpFail(true);
  //     }
  //   };

  //   const googleSignIn = async (loginDataObj) => {
  //     const loginStatus = await Auth.authenticateGmail(loginDataObj, (response) => {
  //       if (response.status === 'success') {
  //         region.getRegions();
  //         navigate('/dashboard', { replace: true });
  //       }
  //     });
  //     return loginStatus;
  //   };
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Signup | Google Rank Check </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <img src={SignupImg} alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign-Up to Google Rank Check Free Account
            </Typography>
            <span
              style={{
                display: 'flex',
                justifyContent: 'end',
                fontSize: '14px',
                marginTop: '-10px',
              }}
            >
              Powered by Marketing Lad
            </span>

            <Typography variant="subtitle" sx={{ mb: 5 }}>
              Already have an account? {''}
              <Link
                onClick={() => {
                  navigate('/login', { replace: true });
                }}
                sx={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                Login
              </Link>
            </Typography>

            {/* <Stack direction="row" spacing={2} display="flex" alignItems="center" justifyContent="center">
              <GoogleLogin
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                variant="outlined"
                className="google-auth"
                style={{ display: 'block', height: '100%' }}
              />
            </Stack> */}

            {/* <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider> */}

            <SignUpForm url={urlParam} />

            <Typography variant="subtitle2" display={'flex'} justifyContent={'flex-end'} mt={-1}>
              Check for &nbsp; <Link href="privacy-policy"> Privacy Policy!</Link>
            </Typography>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
