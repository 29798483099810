import trackerApi from './trackerApi';

class Keyword {
  // Keyword  Class //

  constructor() {
    this.r_k = JSON.parse(localStorage.getItem('r_k')) || {};
  }

  getRemainingKeywords() {
    return JSON.parse(localStorage.getItem('r_k'));
  }

  // Add Keyword //
  addKeyword = async (keywordData, callBack) => {
    try {
      const response = await trackerApi({
        method: 'POST',
        url: `/keyword`,
        data: keywordData,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Delete Keyword //
  deleteKeyword = async (id, callBack) => {
    try {
      const response = await trackerApi({
        method: 'DELETE',
        url: `/keyword/${id}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      callBack({ status: 'error', data: error });
    }
  };

  // Delete All Keywords //
  deleteAllKeywords = async (domainId, callBack) => {
    try {
      const response = await trackerApi({
        method: 'DELETE',
        url: `/keyword/delete-by-domain-id/${domainId}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      callBack({ status: 'error', data: error });
    }
  };

  // Reload All Keywords //
  reloadAllKeywords = (ids, callBack) => {
    trackerApi({
      method: 'POST',
      url: `/position/check-serp-id`,
      data: ids,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error', data: error });
      });
  };

  // Update Keyword //
  updateKeyword = async (id, fav, callBack) => {
    try {
      const response = await trackerApi({
        method: 'PUT',
        url: `/keyword/${id}`,
        data: fav,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      callBack({ status: 'error', data: error });
    }
  };

  // Limit Keyword //
  keywordsLimit = (userId, callBack) => {
    trackerApi({
      method: 'GET',
      url: `/keyword/keyword-limit/${userId}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error', data: error });
      });
  };
}
export default new Keyword();
