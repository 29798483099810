import React, { useState, useEffect, useContext } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { MyContext } from 'src/pages/DashboardAppPage';
import keyword from '../../module/keyword';
import domain from '../../module/domain';
import search from '../../module/search';
import Iconify from '../iconify/Iconify';

const SearchComponent = ({ searchDomain }) => {
  const [keyword, setKeyword] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const {
    keywordData,
    setKeywordData,
    setLoading,
    Loading,
    domainName,
    createData,
    setCount,
    pagenum,
    deviceName,
    page,
    rowsPerPage,
  } = useContext(MyContext);

  useEffect(() => {
    const fetchData = () => {
      search.searchKeywords(searchDomain, keyword, (response) => {
        if (response.status === 'success') {
          setKeywordData(response.data);
        } else {
          console.log('Error occurred while fetching data from the API.');
        }
      });
    };

    const fetchDataWithDebounce = debounce(fetchData, 1000);

    if (searchDomain && keyword) {
      fetchDataWithDebounce();
    } else {
      fetchDataWithDebounce.cancel();
    }

    return () => {
      fetchDataWithDebounce.cancel();
    };
  }, [setKeywordData, keyword, searchDomain]);

  const handleOnChange = (event) => {
    if (event.target.value === '') {
      handleClose();
    } else {
      setKeyword(event.target.value);
    }
  };

  const handleClose = () => {
    setKeyword('');
    domain.getByDomainId(domainName.id, 0, 30, 'Desktop', (response) => {
      if (response.status === 'success') {
        const data = response.data.keywordDetails;
        setKeywordData(data);
      } else {
        console.log('Error occurred while fetching data from the API.');
      }
    });
  };

  return (
    <div>
      <TextField
        label="Keyword Search"
        value={keyword}
        onChange={handleOnChange}
        placeholder="Keyword"
        size="small"
        sx={{ width: '180px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {keyword ? (
                <Iconify sx={{ cursor: 'pointer' }} onClick={handleClose} icon="mdi:clear" />
              ) : (
                <Iconify sx={{ cursor: 'pointer' }} icon="mdi:search" />
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchComponent;