import trackerApi from './trackerApi';

class Domain {
  // Domain  Class //

  // Add Domain  //
  saveDomain = async (domainData, callBack) => {
    try {
      const response = await trackerApi({
        method: 'POST',
        url: `/domain`,
        data: domainData,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Update Domain //
  updateDomain = async (id, domainData, callBack) => {
    try {
      const response = await trackerApi({
        method: 'PUT',
        url: `/domain/${id}`,
        data: domainData,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  


  // Get Domain //
  getByDomainId = async (id, page, rowsPerPage, deviceName, callBack) => {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: `/domain/${id}/${page}/${rowsPerPage}?device=${deviceName}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Get Domains By User Id //
  getByUserId = async (userId, callBack) => {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: `/domain/user-domains/${userId}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Get Domains By User Id Returns Domain and Id only //
  getDomainByUserId = async (userId, callBack) => {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: `/domain/user-domain-list/${userId}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Delete Domain //
  deleteDomain = async (id, callBack) => {
    try {
      const response = await trackerApi({
        method: 'DELETE',
        url: `/domain/${id}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Domain Limit //
  domainLimit = (id, callBack) => {
    trackerApi({
      method: 'GET',
      url: `/domain/domain-limit/${id}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log(error, 'error');
        callBack({ status: 'error' });
      });
  };
}
export default new Domain();
