import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Button, Tooltip, CircularProgress, Card } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import ReactApexChart from 'react-apexcharts';
import position from '../../../module/position';
import { GarphContext } from '../../../components/keyword-history/keywordHistory';

Chart.register(...registerables);

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.object.isRequired, // Use object instead of array
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ id, title, subheader, chartLabels, chartData, ...other }) {
  const { setKeys, setValues } = useContext(GarphContext);
  const [loading, setLoading] = useState(false);

const chartOptions = {
  plotOptions: { bar: { columnWidth: '100%' } ,area: { fillTo: 'end' } },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.3,
      stops: [0, 90, 100],
    },
    invert: true, // Ensures the fill is below the line when the y-axis is reversed
  },



  labels: chartLabels,
  xaxis: { type: 'datetime' },
  yaxis: {
    min: 0,
    max: 100,
    reversed: true, // Keep the reversed axis for your scale
  },
  stroke: {
    curve: 'smooth',
    colors: ['#6ea2c9'],
    width: 2,
  },
  colors: ['#31045c'],
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: (y) => {
        if (typeof y !== 'undefined') {
          return `${y.toFixed(0)}`;
        }
        return y;
      },
    },
  },
};

  const graphData = (range) => {
    setLoading(true);
    position.getGraphData(id, range, (response) => {
      if (response.status === 'success') {
        const data = response.data;
        const abc = data.map((d) => d.position);
        setValues(abc);
        const def = data.map((d) => d.creationDate);
        setKeys(def);
      }
      setLoading(false);
    });
  };

  return (
    <>
      <Card>
        <Tooltip title="1 month" placement="top">
          <Button sx={{ float: 'right' }} onClick={() => graphData('1Month')}>
            1-Mth
          </Button>
        </Tooltip>
        <Tooltip title="3 months" placement="top">
          <Button sx={{ float: 'right' }} onClick={() => graphData('3Month')}>
            3-Mth
          </Button>
        </Tooltip>
        <Tooltip title="6 months" placement="top">
          <Button sx={{ float: 'right' }} onClick={() => graphData('6Month')}>
            6-Mth
          </Button>
        </Tooltip>
        <Tooltip title="1 year" placement="top">
          <Button sx={{ float: 'right' }} onClick={() => graphData('1Year')}>
            1-Year
          </Button>
        </Tooltip>

        <CardHeader title={title} subheader={subheader} />

        <div>
          {!chartData ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={20} />
            </div>
          ) : (
            <ReactApexChart type="area" series={chartData.datasets} options={chartOptions} height={364} width={1100} />
          )}
        </div>
      </Card>
    </>
  );
}
