import React, { useState, useEffect, useRef, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CsvUploader from 'src/components/csv';
import {
  Autocomplete,
  Box,
  ButtonGroup,
  CircularProgress,
  createFilterOptions,
  Grid,
  Grow,
  MenuItem,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { toast, ToastContainer } from 'react-toastify';
import Auth from 'src/module/Auth';
import Iconify from '../iconify';
import './keyword.css';
import { MyContext } from '../../pages/DashboardAppPage';
import keyword from '../../module/keyword';

const filter = createFilterOptions();

export default function AddKeyword(keywordsLimit) {
  const [openState, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(true);
  const [mobileSelected, setMobileSelected] = useState(false);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState('Keyword *');
  const [regions, setRegions] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [countryCode, setCode] = useState('');
  const [domainValue, setDomainValue] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [disableAll, setDisableAll] = useState(false);

  const { domainName, open, tableLoading, setTableLoading, category } = useContext(MyContext);
  const keywordRef = useRef();
  const domainRef = useRef();
  const searchQueryRef = useRef(null);

  useEffect(() => {
    if (keyword.getRemainingKeywords() !== undefined && keyword.getRemainingKeywords() == 0) {
      setDisableAll(true);
    }
    getAllRegions();
    if (open) {
      setOpen(true);
    }
  }, [open, disableAll]);

  const filteredData = React.useMemo(() => {
    return regions?.filter((item) => {
      return item?.symbol.toLowerCase().includes(searchQuery?.toLowerCase());
    });
  }, [regions, searchQuery]);

  const keywordData = [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelected(true);
    setMobileSelected(false);
    setOpen(false);
    setDisable(true);
    setError(false);
    setDisableAll(false);
    setSearchQuery('');
  };

  const handleSaveKeyword = (e) => {
    setLoader(true);
    e.preventDefault();
    setDisable(true);

    if (domainRef.current.value !== undefined) {
      createKeyword();
    } else {
      setError(true);
      setDisable(false);
      setLoader(false);
    }
  };

  const handleSearchFocus = () => {
    if (searchQueryRef && searchQueryRef.current) {
      searchQueryRef.current.focus(); // Focus the search text field when domain region dropdown opens
    }
  };
  const createKeyword = () => {
    const keywords = keywordRef.current.value.split(/\r?\n/);
    keywords.forEach((key) => {
      if (key !== '') {
        const objData = {
          keyword: key,
          domainId: domainName.id,
          url: domainRef.current.value,
          device: selected ? 'desktop' : 'mobile',
          // tag: categoryValue?.tag,
          code: countryCode ? countryCode : 'us',
        };


        keywordData.push(objData);
      }
    });

    if (keywordData.length > 0) {
      setDisable(true);

      keyword.addKeyword(keywordData, (response) => {
        if (response.status === 'success') {
          setLoader(false);
          setTableLoading(!tableLoading);
          toast.success('Keyword Added ');
          setOpen(false);
          setDisable(false);
          setCode('');
          setSearchQuery('');
        } else {
          toast.error('Something went wrong');
          setOpen(false);
          setLoader(false);
          setCode('');
          setDisable(false);
          setSearchQuery('');
        }
      });
    }
  };

  const handleDesktopClick = () => {
    setSelected(true);
    setMobileSelected(false);
  };

  const handleMobileClick = () => {
    setMobileSelected(true);
    setSelected(false);
  };

  const getAllRegions = () => {
    setRegions(JSON.parse(localStorage.getItem('Regions')));
  };

  const handleValue = () => {
    setValue('Hit Enter to Add Multiple Keywords');
  };

  const handleBlur = () => {
    setValue('Keyword');
    setDisable(false);
    if (keywordRef.current.value.split(/\r?\n/).includes('')) {
      setDisable(true);
    }
  };

  const handleCode = (code, url) => {
    setCode(code);
    setDomainValue(url);
  };

  return (
    <>
      <Button disabled={!domainName?.id} variant="outlined" onClick={handleClickOpen}>
        Add Keyword
      </Button>
      <Dialog open={openState} onClose={handleClose}>
        <DialogTitle>
          New Keyword
          {keyword?.getRemainingKeywords !== undefined && keyword?.getRemainingKeywords !== -1 ? (
            <span style={{ float: 'right', fontSize: '14px', fontWeight: '600' }}>
              {' '}
              Remaining Keywords : {keyword?.getRemainingKeywords()}{' '}
            </span>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 2, width: '45ch' },
            }}
          >
            <Grid item>
              <TextField
                disabled={keyword?.getRemainingKeywords() == 0 ? true : false || disableAll}
                id="outlined-name"
                onFocus={handleValue}
                onBlur={handleBlur}
                label={value}
                inputRef={keywordRef}
                multiline
                helperText={ '* Maximum 20 keywords at once'}
                sx={{
                  width: '408px',
                }}
              />
            </Grid>
            <Box component="form" display="flex" gap={1} flexWrap="wrap" sx={{ height: 50 }}>
              <TextField
                sx={{ width: 190 }}
                disabled={keyword?.getRemainingKeywords() == 0 ? true : false || disableAll}
                error={error}
                id="domain"
                select
                label={error ? 'Select Domain Region *' : 'Domain Region'}
                inputRef={domainRef}
                onChange={() => setError(false)}
                // defaultValue="google.com"
                // onOpen={handleSearchFocus} // Call the function when dropdown
                onClick={(event) => event.stopPropagation()}
              >
                <TextField
                  ref={searchQueryRef} // Add a ref to the search text field
                  disabled={keyword?.getRemainingKeywords() == 0 ? true : false || disableAll}
                  size="small"
                  type="text"
                  placeholder="Search By Country"
                  defaultValue={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  sx={{ width: '190px !important' }}
                />

                {filteredData?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.url}
                    onClick={(event) => {
                      event.preventDefault(); // Prevent default behavior of closing the MenuItem
                      event.stopPropagation();
                      handleCode(option.code, option.url);
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={option.code}
                      svg
                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                      cdnSuffix="svg"
                      title="flag"
                    />
                    &nbsp; {option.url}
                  </MenuItem>
                ))}
              </TextField>

              <Autocomplete
                value={categoryValue}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setCategoryValue(newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setCategoryValue(newValue.inputValue);
                  } else {
                    setCategoryValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.tag);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      tag: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={category}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.tag;
                }}
                renderOption={(props, option) => <li {...props}>{option?.tag}</li>}
                sx={{ width: 200 }}
                freeSolo
                renderInput={(params) => <TextField {...params} label="Category" />}
              />
            </Box>
          </Box>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <ButtonGroup
                variant="outlined"
                aria-label="split button"
                sx={{ mb: '10px' }}
              >
                <Tooltip title=" Device Desktop">
                  <Button
                    onClick={() => handleDesktopClick()}
                    variant={selected ? 'contained' : 'outlined'}
                    color={selected ? 'success' : 'primary'}
                  >
                    <Iconify icon={'material-symbols:desktop-mac-outline'} />
                  </Button>
                </Tooltip>
                <Tooltip title=" Device Mobile">
                  <Button
                    variant={mobileSelected ? 'contained' : 'outlined'}
                    onClick={() => handleMobileClick()}
                    color={mobileSelected ? 'success' : 'primary'}
                  >
                    <Iconify icon={'mdi:mobile-phone'} />
                  </Button>
                </Tooltip>
              </ButtonGroup>
              <CsvUploader
                setLoader={setLoader}
                setUploadProgress={setUploadProgress}
                setOpen={() => setOpen(false)}
                keywordData={keywordData}
              />
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={keyword?.getRemainingKeywords() == 0 ? true : false || disable} onClick={handleSaveKeyword}>
            {loader ? <CircularProgress size={20} /> : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* {loader && <CircularProgress />}  */}

      <ToastContainer />
    </>
  );
}
