import trackerApi from './trackerApi';

class Position {
  // Position  Class //

  // Refresh Position //
  refreshPosition = async (id, callBack) => {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: `/position/check-serp-id/${id}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Other Position Links //
  getOtherPositionLinks = async (id, page, callBack) => {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: `/position/other-links-keywordId/${id}/${page}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Bar chart //
  getGraphData = async (keywordId, type, callBack) => {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: `/position/get-Graph-stats/${keywordId}/${type}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      console.log('failed', error);
      callBack({ status: 'error', data: error });
    }
  };

  // Card Data //
  getCardData = (ids, callBack) => {
    const response = trackerApi({
      method: 'POST',
      url: `/position/dashboard-data`,
      data: ids,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Failed to set the new password:', error);
        callBack({ status: 'error' });
      });
  };
}
export default new Position();
